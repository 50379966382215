/* basic */
*{
    margin: 0%;
    padding: 0%;
    box-sizing: content-box;
}
html{
    font-size: 89%;
}
h1,h2,h3,h4,h5{
    font-family: "Urbanist", sans-serif ;
}
a ,li{
    text-decoration: none;
    list-style: none;
    font-size: 1.3rem;
     text-transform: uppercase;
    color: white;
}
p{
    letter-spacing: 1px;
    font-size: 1.3rem;
    font-weight: 300;
}    
a:hover{
    background-color:orangered;
    border-radius:5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    -ms-border-radius:5px;
    -o-border-radius:5px;
    cursor: pointer;
}
a:active{
    background-color: rgb(76, 76, 246);
}
.container{
    max-width:auto;
    margin: auto;
    padding:1rem 2rem ;
    height: 100vh;
}
.grid{
    display: grid;
}
.grid , .grid-col-two{
    grid-template-columns: repeat(2,1fr);
}
.grid-col-four{
    grid-template-columns: repeat(4,1fr);
} 
.grid-col-three{
    grid-template-columns:repeat(3,1fr) ;
}

/*reuseable=====================  */ 
.Number-sec{
    display: flex;
    gap: 1rem;
}
.contact-sec{
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:1rem;
    padding: 0.8rem;
    font-size: 2rem;
    box-shadow: 2px 2px 3px white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.whatsapp-sec{
   background-color: rgb(109, 239, 120);
    color:white;
    border-radius: 5px;
    a{
        color: #0d0d0d;
       text-shadow: none;
    }
}

.cal-sec{
    background-color: rgb(26, 26, 250);
}

.contact-sec:hover
{
    background-color: rgb(255, 170, 0);
    cursor: pointer;
}
.contact-sec:active
{
    background-color: rgb(102, 102, 250);
}

.common-Heding
{
    font-size: 2rem;
}

.download-btn {
    display: inline-block;
    text-align: center;
    background-color: #f7bd0f;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;
    padding: 15px 40px;
    border-radius:7px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.1s ease, box-shadow 0.2s ease, background-color 0.3s ease;
    position: relative;
    animation: glow 0.5s infinite alternate;
    border: 2px solid;
    /*Addglowinganimation*/
}

.download-btn span {
    margin-right: 10px;
}

/* Hover and glow effects */
.download-btn:hover {
    transform: scale(1.05);
    background-color: #FFC107; /* Lighter gold on hover */
    box-shadow: 0 6px 15px rgba(255, 215, 0, 0.8), 0 0 30px rgba(255, 215, 0, 0.5); /* Glow effect */
}

/* Glowing animation */
@keyframes glow {
    0% {
        box-shadow: 0 9px 10px rgba(255, 217, 0, 0.762), 0 0 10px rgba(255, 215, 0, 0.2);
        width:18rem ;
    }
    100% {
        box-shadow: 0 9px 20px rgba(255, 217, 0, 0.788), 0 0 20px rgba(255, 217, 0, 0.877), 0 0 40px rgba(255, 215, 0, 0.4);
        width:22rem ;
    }
}

.download-btn:active {
    transform: scale(0.98);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}
/*reuseable=====================  */  
/* NavBar Section start====================================== */

nav .nav-container{
  display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

 ul{
    display: flex;
}
ul li a{
    display: block;
    color: white;
    padding: 0.5rem;
    margin: 0 0.5rem;
  
}
/* nav toggle btn */
.toggle-nav{
   display: none;
    color: white;
    position: absolute;
    right: 1rem;
    font-size: 2rem;
}
.logo img{
    width:6.5rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
/* NavBar Section End=================================================== */
/* Header section Start=================================================== */
#Header{
    .container{
        height: 100vh;
        background-image: url("../src/Images/hero-bg-img.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
  .Header-content{

        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        padding-top: 10rem;
        text-shadow: 4px 3px 4px black;
        font-weight: 800;
        gap:1rem;
        font-size: 1.2rem;
        color: white;
        .Header-sub-text{
            background-color: rgb(255, 140, 0);
            color: black;
            text-shadow: 2px 2px 2px white;
            font-weight: 800;
            font-size:1.6rem ;
            text-align: center;
        }
    
    }

}

/* Header section Start=================================================== */
/* About section Start============================================ */
#Aboutus .container{
    background-image: url("../src/Images/coins-removebg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .Aboutus-img{
        display: flex;
        align-items: center;
        justify-content: center;
    img{
        width: 36%;
    }
}
.Aboutus-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    word-spacing:2px;
    font-weight: 600;
   
       p{
        font-size: 1.5rem;
        color: rgba(0, 0, 0, 0.932);
        text-shadow: 2px 2px 2px white;
    }
}
}
/* About section End============================================ */

/* DownloadApp Sec Start=========================================== */
#Download .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:1rem;
    background-image: url("../src/Images/coins-removebg.png");
    background-position: center;
}
.Download-img-sec{
 
    display: flex;
    gap:2rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    img{
        width:15rem;
        border: 5px solid;
    }
   
}
/* DownloadApp Sec End=========================================== */

/* Rate sec start============================================ */
#Rates .container
{
     display: flex;
     flex-direction: column;
 align-items: center;
    justify-content: center; 
    gap:3rem;
    .grid{
        gap:2rem;
    }
}
.card{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;
   font-size: 1rem;
   padding: 4rem;
   box-shadow: 3px 2px 6px gray;
   border-radius:3px ;
   -webkit-border-radius:3px ;
   -moz-border-radius:3px ;
   -ms-border-radius:3px ;
   -o-border-radius:3px ;
  
}
/* Rate sec start============================================ */


/* Testimonia  sec start===================================== */
.Testimonial-content
{
    font-size: 1.6rem;
    gap:1rem;
}
.user-review-box{
    /* border: 2px solid; */
    padding: 1rem;
    box-shadow: 2px 2px 2px gray;
}
.user-profile{
   
    .user-img{
        height:5rem ;
        width:5rem ;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        margin: 1rem;
}
    #user1{
        background-color: #f7bd0f;
    }
    #user2{
        background-color: purple;
    }
    #user3{
        background-color:rgb(0, 98, 255);
    }
    #user4{
        background-color:rgb(98, 255, 0);
    }
    
}

.user-rates{
    i{
        color: #FFC107;
    }
}

/* Testimonia  sec start===================================== */

/* Howtoplay sec start======================================== */
#Howtoplay .container
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:1rem;
  
    flex-wrap: wrap;
 img{
        width:30rem;
    }
    .Howtoplay-video{
        display: flex;
      flex-direction: column;
        align-items: center;
     justify-content: center;
        text-align: center;
        gap: 2rem;}
    
}
.Howtoplay-content
{
    display: flex;
   flex-direction: column;
   gap:2rem;
a{
    font-size: 2rem;
}
p{
    font-size:1.4rem ;
    font-weight: 700;
}
}
.rules{
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
}
.watchnow-btn{
    background-color: red;
    color: aliceblue;
    padding: 1rem;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

/* Howtoplay sec End======================================== */

.Footer{
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 3rem;
    background-color: #0d0d0d;
    color: white;
    font-size: 1.4rem;
}
/* Footer End ==================================================== */

/* Responsive Section start================================== */
@media (width<=1050px)
{
    html{
        font-size: 75%;

    }
    .grid,.grid-col-two{
        grid-template-columns: repeat(1,1fr);
    }
    .grid-col-three{
        grid-template-columns: repeat(2,1fr);
    }
    .grid-col-four{
        grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
    }
.toggle-nav{
    display:flex;
}
nav .nav-container{
    flex-direction: column;
    align-items: start;

}
.Navbar-menu
{
    width: 100%;
    text-align: center;
       background-color:rgba(0, 0, 0, 0.389);
}
nav ul{
display: none;
flex-direction: column;
text-align: center;
width:100%;
margin-bottom: 0.25%;
}
nav ul.open{
display: flex;
}
nav ul li{
    width: 100%;
    text-align: center;
}
nav ul li a{
    margin: 0.25rem,0.5rem;
}
}
@media (width<=668px)
{
    html{
        font-size: 60%;
}

/* Download App */
.Download-img-sec{
 img{
        width:12rem;
        border: 3px solid;
    }
}}
@media (width<=471px)
{
    /* Aboutus */
    .Aboutus-content{
      p{
            font-size: 1.3rem;
            color: rgba(0, 0, 0, 0.932);
            text-shadow: 2px 2px 2px white;
        }
    }
    /* How to play=============== */
    #Howtoplay .container
{
   img{
        width:25rem;
    }
}
.Testimonial-content
{
    font-size: 1.3rem;
    gap:0.9rem;
}

.user-profile{
   
    .user-img{
        height:3rem ;
        width:3rem ;
}
}
}

@media (width<=400px)
{
    .Aboutus-content{
        font-size: 1rem;
    }
    .download-btn {
        font-size: 1.8rem;
    }

    @keyframes glow {
        0% {
            box-shadow: 0 9px 10px rgba(255, 217, 0, 0.762), 0 0 10px rgba(255, 215, 0, 0.2);
            width:18rem ;
        }
        100% {
            box-shadow: 0 9px 20px rgba(255, 217, 0, 0.788), 0 0 20px rgba(255, 217, 0, 0.877), 0 0 40px rgba(255, 215, 0, 0.4);
            width:21rem ;
        }
    }
    
.Download-img-sec{
    
    img{
        width:10rem;
    }
}
}
/*  */
@media (width<=375px)
{
    .download-btn {
        font-size: 1.4rem;
    }
    @keyframes glow {
        0% {
            box-shadow: 0 9px 10px rgba(255, 217, 0, 0.762), 0 0 10px rgba(255, 215, 0, 0.2);
            width:15rem ;
        }
        100% {
            box-shadow: 0 9px 20px rgba(255, 217, 0, 0.788), 0 0 20px rgba(255, 217, 0, 0.877), 0 0 40px rgba(255, 215, 0, 0.4);
            width:17rem ;
        }
    }
.Download-img-sec{
    
    img{
        width:9rem;
    }
}
}
